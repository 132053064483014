import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-intl'

const PageTitle = ({ title, subtitle, intl }) => {
  return (
    <section className="page-title page-title-4 bg-secondary">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="uppercase mb0">{title}</h3>
          </div>
          <div className="col-md-6 text-right">
            <ol className="breadcrumb breadcrumb-2">
              <li>
                <Link to="/">{intl.formatMessage({ id: 'navBar.home' })}</Link>
              </li>
              <li className="active">{subtitle}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(PageTitle)
