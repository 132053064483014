import React from 'react'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'

const JobCard = ({ job }) => {
  const { title, createdAt, id } = job

  return (
    <div className="col-md-4 col-sm-6">
      <div className="feature bg-secondary p32 pt40 pb40 mb0">
        <span className="fade-1-4 mb16 display-block">{createdAt}</span>
        <h4>{title}</h4>
        <a href="#" className="mb0 right">
          <h6 className="uppercase mb0 color-primary fade-on-hover">
            <Link to={`/job/${id}`}><FormattedMessage id="jobs.description" /></Link>
            <i className="ti-arrow-right" />
          </h6>
        </a>
      </div>
    </div>
  )
}

export default JobCard
