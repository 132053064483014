import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl, Link } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import JobCard from '../components/JobPage/JobCard'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'

const Job = props => {
  const { currentPage, numJobsPages } = props.pageContext
  const { intl } = props

  const isFirst = currentPage === 1
  const isLast = currentPage === numJobsPages

  const prevPage = currentPage - 1 === 1 ? `/jobs/` : `/jobs/${currentPage - 1}`
  const nextPage = `/jobs/${currentPage + 1}`

  const { data } = props
  return (
    <Layout>
      <SEO title={intl.formatMessage({id: 'job.list.title'})} />
      <PageTitle
        title={intl.formatMessage({id: 'job.list.title'})}
        subtitle={intl.formatMessage({id: 'job.list.subTitle'})}
      />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3 className="mb64 mb-xs-32">
                {intl.formatMessage({id: 'job.list.title'})}
              </h3>
            </div>
          </div>
          <div className="row">
            {data.jobs.edges.map(({ node }) => (
              <JobCard key={node.id} job={node} />
            ))}
          </div>
          <br />
          <div className="row">
            <div className="text-center">
              <ul className="pagination">
                {!isFirst && (
                  <li>
                    <Link to={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                )}

                {Array.from({ length: numJobsPages }, (_, i) => (
                  <li className={i + 1 === currentPage ? `active` : ``}>
                    <Link key={i} to={`/jobs/${i === 0 ? '' : i + 1}`}>
                      {i + 1}
                    </Link>
                  </li>
                ))}
                {!isLast && (
                  <li>
                    <Link to={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query getJobs($skip: Int!, $limit: Int!) {
    jobs: allContentfulJob(
      skip: $skip
      limit: $limit
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          title
          id: contentful_id
          createdAt(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`
export default injectIntl(Job)
